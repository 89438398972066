import * as React from 'react';
import styled from 'styled-components';
import Button from '@components/buttons';
import { Modal, Body, Footer } from './base';
import { Trans } from '@lingui/react';

const StyledModal = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-x: hidden;
`;

const StyledBody = styled(Body)`
  min-width: 345px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 3px;
  padding: 0;
  margin: 0 auto;
  background-color: white;
  box-sizing: content-box;
`;

const StyledFooter = styled(Footer)`
  /* min-width: 345px; */
  border-top: none;
  margin: 0 auto;
  padding: 0 30px 24px;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 24px 30px;
  padding-bottom: 24px !important; /* overwrite footer dynamic offset padding */
  margin: 0;
`;

const Item = styled.li`
  display: block;
  font-size: 11px;
  color: ${props => props.theme.colors.neutrals.gray};
  font-weight: 600;

  & + & {
    margin-top: 2em;
  }
`;

const Anchor = styled(Button)`
  font-size: 16px;
  color: ${props => props.theme.colors.neutrals.black};
  font-weight: normal;
  text-align: left;
`;

const CancelButton = styled(Button)`
  font-size: 16px;
  color: ${props => props.theme.colors.neutrals.gray};
  font-weight: normal;
`;

declare type LinkType = {
  href: string;
  as?: string;
  text: string | React.ReactNode;
};

declare type LinksModalProps = {
  open?: boolean;
  links: Array<LinkType>;
  onClose(): void;
};

const LinksModal = ({ open, links, onClose }: LinksModalProps) => {
  return (
    <StyledModal fullScreen={false} open={open} handleClose={onClose}>
      <StyledBody>
        <MenuList>
          {links.map((item, i) => {
            return (
              <Item key={i}>
                <Anchor
                  href={item.href}
                  appearance="appearance"
                  style={{ display: 'block', paddingBottom: '9px' }}
                  onClick={onClose}
                >
                  {item.text}
                </Anchor>
              </Item>
            );
          })}
        </MenuList>
        <StyledFooter>
          <CancelButton onClick={onClose} appearance="link">
            <Trans id="settings_modal.secondary_button" />
          </CancelButton>
        </StyledFooter>
      </StyledBody>
    </StyledModal>
  );
};

export default LinksModal;
