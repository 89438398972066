import React, { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { isFuture } from 'date-fns';
import { useRouter } from 'next/router';
import useFlags from '@lib/hooks/useFlags';
import messageStripe from '@components/MessageStripe';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import { isEnabledOnPage } from '@lib/utils';
import { SubscriptionStatus } from '@gql/generated';

const MessageStripe = styled(messageStripe)``;

const TEXTS = {
  guest: {
    first: {
      se: 'Nyttja friskvårdsbidraget – investera i din hemmaträning!  ➜',
    },
    last: {
      se: '🔥 Just nu: Bara 1899 SEK för en årsprenumeration (spara upp till 849 SEK) ➜',
    },
  },
  user: {
    first: {
      se: 'Nyttja friskvårdsbidraget – investera i ännu mer hemmaträning ➜',
    },
    last: {
      se: '🔥 Just nu: Bara 1899 SEK för en årsprenumeration (spara upp till 849 SEK) ➜',
    },
  },
};

const start_date = new Date(Date.UTC(2024, 9, 3, 23));
const end_date = new Date(Date.UTC(2025, 0, 16, 23));

const KampanjBanner = ({}) => {
  const router = useRouter();
  const [flags] = useFlags();
  const lang = useContext(LanguageContext);
  const [currentUser] = useContext(UserContext);

  const ENABLED_PATHS = [
    { path: '/[lang]', exact: true },
    { path: '/[lang]/pricing', exact: true },
    { path: '/[lang]/courses', exact: true },
    { path: '/[lang]/programs', exact: true },
    { path: '/[lang]/yearly', exact: true },
    { path: '/[lang]/videos', exact: false },
  ];

  let showYearlyCampaign = false;
  if (
    !(
      currentUser?.subscription?.status === SubscriptionStatus['Active'] &&
      currentUser?.subscription?.currentSubscriptionPlan?.planType === 'yearly'
    )
  ) {
    showYearlyCampaign = true;
  }

  const allowed_on_page =
    lang === 'se' && isEnabledOnPage(ENABLED_PATHS, router.pathname);

  if (
    !allowed_on_page ||
    !showYearlyCampaign ||
    !flags?.yearly_campaign ||
    isFuture(start_date) ||
    !isFuture(end_date)
  ) {
    return null;
  }

  const user_text =
    !currentUser?.id || !currentUser?.paymentTypeRenews
      ? TEXTS.guest
      : TEXTS.user;

  const text = isFuture(Date.UTC(2024, 10, 31, 23))
    ? user_text['first'].se
    : user_text['last'].se;

  const isPricingPage = router?.pathname.startsWith('/[lang]/pricing');

  const bgColor = isPricingPage ? '#119edd' : '#D9E8E7';
  const color = isPricingPage ? 'white' : '#242424';

  return (
    <MessageStripe bg={bgColor} color={color}>
      <Link href={`/${lang}/friskvardsbidrag`}>{text}</Link>
    </MessageStripe>
  );
};

export default KampanjBanner;
