import { normalizeImagePath } from '@lib/helpers';
import { isFuture } from 'date-fns/isFuture';
import { compareDesc } from 'date-fns/compareDesc';
import { compareAsc } from 'date-fns/compareAsc';

const BundlePolicy = {
  typePolicies: {
    BundleItem: {
      fields: {
        promotionalImage: normalizeImagePath,
        heroImage: normalizeImagePath,
        liveEvents: {
          read(value, { readField }) {
            const items = [...(value?.items || [])]
              .filter(live => {
                if (readField('isExpired', live)) {
                  return false;
                }
                return true;
              })
              .sort((a, b) => {
                const A_eventDate = new Date(readField('eventDates', a)[0]);
                const B_eventDate = new Date(readField('eventDates', b)[0]);
                const A_isExpired = readField('isExpired', a);
                const B_isExpired = readField('isExpired', b);
                if (!A_isExpired && B_isExpired) {
                  return -1;
                } else if (!B_isExpired) {
                  return 1;
                } else if (isFuture(A_eventDate) && isFuture(B_eventDate)) {
                  // if both events are upcoming we sort them ASC
                  return compareAsc(A_eventDate, B_eventDate);
                } else {
                  return compareDesc(A_eventDate, B_eventDate);
                }
              });

            return {
              viewAllUrl: value.viewAllUrl,
              items,
            };
          },
        },
      },
    },
  },
};

export default BundlePolicy;
