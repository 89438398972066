import React, { useContext, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { useRouter } from 'next/router';
import { Trans } from '@lingui/react';
import gql from 'graphql-tag';
import Stickable from '@components/Stickable';
import LinksVideos from './LinksVideos';
import { PROGRAMS_PAGE_SLUG, VIDEOS_GENRES_ID } from '@lib/constants';
import LanguageContext from '@lib/contexts/languageContext';

const GET_PRIMARY_LINKS = gql`
  query taxonomies($filter: String) {
    taxonomies(filter: $filter) {
      terms {
        id
        name
        slug
      }
    }
  }
`;

const buildMenuItems = (terms, { locale, router }) => {
  const items = [
    {
      id: 'overview',
      link: `/${locale}/videos/overview `,
      text: <Trans id="subheader.overview" message="Overview" />,
      active: `/[lang]/videos` === router.pathname,
    },
  ];

  if (Array.isArray(terms)) {
    items.push(
      ...terms.map(item => {
        return {
          id: item.id,
          link: `/${locale}/videos/genres/${item.slug}`,
          text: item.name,
          genre: item.slug,
          active: item.slug === router.query?.genre,
        };
      })
    );
  }

  items.push(
    {
      id: 'playlist',
      link: `/${locale}/playlists`,
      text: <Trans id="subheader.playlists" message={'Playlists'} />,
      active: `/[lang]/playlists` === router.pathname,
    },
    {
      id: 'program',
      link: `/${locale}/${PROGRAMS_PAGE_SLUG}`,
      text: <Trans id="subheader.programs" message="Programs" />,
      active: ['/[lang]/programs', '/[lang]/program'].includes(router.pathname),
    }
  );
  return items;
};

const order = ['yoga', 'meditation', 'breathing', 'workout', 'grow'];
const VideosSubheader = () => {
  const router = useRouter();
  const lang = useContext(LanguageContext);
  const { error, loading, data } = useQuery(GET_PRIMARY_LINKS, {
    variables: { filter: VIDEOS_GENRES_ID },
  });

  if (error) return null;

  let menuItems = [];

  if (data?.taxonomies?.length > 0) {
    const { taxonomies } = data;
    const terms =
      [
        {
          id: 'all',
          name: <Trans id="metadata.all" message="All" />,
          slug: 'all',
        },
        ...taxonomies[0]?.terms,
      ]?.sort((a, b) => {
        return order.indexOf(a.slug) > order.indexOf(b.slug) ? 1 : -1;
      }) || [];

    menuItems = buildMenuItems(terms, { locale: lang, router });
  }

  return (
    <Stickable scrollAfterY={77}>
      {showSticky => {
        if (loading) {
          return (
            <LinksVideos showSticky={showSticky} loading={loading} terms={[]} />
          );
        }

        return <LinksVideos showSticky={showSticky} terms={menuItems} />;
      }}
    </Stickable>
  );
};

export default VideosSubheader;
