import { default as MaterialButton } from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';
import Icon from './Icon';
import Span from './layout/Span';
import Spinner from './Spinner';

export function colors(theme) {
  return {
    'default-blue': {
      backgroundColor: theme.colors.primary['blue-2'],
      hover: theme.colors.interactive['color-11'],
      active: theme.colors.interactive['color-12'],
      disabled: theme.colors.interactive['color-3'],
      color: theme.colors.neutrals['white'],
    },
    'default-pink': {
      backgroundColor: theme.colors.primary['pink-2'],
      hover: theme.colors.interactive['color-13'],
      active: theme.colors.interactive['color-14'],
      disabled: theme.colors.primary['pink-3'],
      color: theme.colors.neutrals['white'],
    },
    'default-green': {
      backgroundColor: theme.colors.secondary['green-4'],
      hover: theme.colors.interactive['color-9'],
      active: theme.colors.interactive['color-10'],
      disabled: theme.colors.interactive['color-2'],
      color: theme.colors.neutrals['white'],
    },
    'default-black': {
      backgroundColor: theme.colors.interactive['color-15'],
      hover: theme.colors.interactive['color-16'],
      active: theme.colors.interactive['color-17'],
      disabled: theme.colors.interactive['color-2'],
      color: theme.colors.neutrals['white'],
    },
    'default-white': {
      backgroundColor: theme.colors.neutrals['white'],
      hover: theme.colors.secondary['gray-4'],
      active: theme.colors.neutrals['white'],
      disabled: theme.colors.interactive['color-2'],
      color: theme.colors.neutrals['black'],
    },
    'default-gold': {
      backgroundColor: '#CCB375',
      hover: 'rgba(204, 179, 117, .8)',
      active: 'rgba(204, 179, 117, .8)',
      disabled: theme.colors.interactive['color-2'],
      color: theme.colors.neutrals['black'],
    },
    'default-purple': {
      backgroundColor: theme.colors.channels.meditation,
      hover: 'rgba(150, 87, 128, .8)',
      active: 'rgba(150, 87, 128, .8)',
      disabled: theme.colors.interactive['color-2'],
      color: theme.colors.neutrals['white'],
    },
    'dark-blue': {
      backgroundColor: 'rgb(36, 50, 64)',
      hover: 'rgba(36, 50, 64, 0.9)',
      active: 'rgba(36, 50, 64, 0.8)',
      disabled: 'rgba(36, 50, 64, 0.6)',
      color: theme.colors.neutrals['white'],
    },
    stone: {
      backgroundColor: theme.colors.primary.stone,
      hover: theme.colors.primary.stone,
      active: theme.colors.primary.stone,
      disabled: theme.colors.secondary['gray-4'],
      color: theme.colors.neutrals['white'],
    },
    'stone-outline': {
      backgroundColor: theme.colors.secondary['gray-5'],
      hover: theme.colors.primary.stone,
      active: theme.colors.primary.stone,
      disabled: theme.colors.secondary['gray-4'],
      color: theme.colors.primary.stone,
    },
    green: {
      backgroundColor: theme.colors.primary.green,
      hover: theme.colors.primary.green,
      active: theme.colors.primary.green,
      disabled: theme.colors.secondary['gray-4'],
      color: theme.colors.neutrals['white'],
    },
  };
}

function sharedStyling(theme, width) {
  return `
    cursor: pointer;
    border: 0;
    width: ${width ? '100%' : 'auto'};
    font-weight: 600;
    font-size: 13px;
    outline: none;
    &:first-letter {
      text-transform: uppercase;
    }
  `;
}

function defaultStyling(
  theme,
  appearance,
  borderRadiuslessRight,
  borderRadiuslessLeft
) {
  return `
    background-color: ${colors(theme)[appearance].backgroundColor};
    color: ${colors(theme)[appearance].color};
    
    border-radius: 5px;
    ${borderRadiuslessRight ? 'border-top-right-radius: 0' : ''};
    ${borderRadiuslessRight ? 'border-bottom-right-radius: 0' : ''};
    ${borderRadiuslessLeft ? 'border-top-left-radius: 0' : ''};
    ${borderRadiuslessLeft ? 'border-bottom-left-radius: 0' : ''};
    
    padding: 12px 24px;

    &:hover {
      background-color: ${colors(theme)[appearance].hover};
    }

    &:active {
      background-color: ${colors(theme)[appearance].active};
    }

    &:disabled{
      background-color: ${colors(theme)[appearance].disabled};
      pointer-events: none;
    }

    i {
      color: ${colors(theme)[appearance].color};
    }
  `;
}

function linkStyling({ theme, color, alignement }) {
  return `
    background-color: transparent;
    color: ${color ? color : theme.colors.primary['blue-2']};
    &:hover {
      color: ${color ? color : theme.colors.primary['blue-1']};
    }
    &:disabled{
      color: ${theme.colors.neutrals['gray-3']};
      cursor: not-allowed;
      pointer-events: none;
    }
    padding: 0;
    text-align: ${alignement ? alignement : 'left'};
  `;
}

function outlineStyling(
  theme,
  active,
  borderRadiuslessRight,
  borderRadiuslessLeft,
  color,
  size
) {
  return `
    background-color: ${
      active ? theme.colors.primary['blue-2'] : 'rgba(255, 255, 255, 0.2)'
    };
    
    ${
      color
        ? `color: ${color}`
        : `color: ${
            active
              ? theme.colors.neutrals['white']
              : theme.colors.neutrals['gray']
          };`
    }
    
    border-radius: ${size === 'small' ? '3px' : '5px'};
    ${borderRadiuslessRight || borderRadiuslessLeft ? 'margin: 0' : ''}
    ${borderRadiuslessRight ? 'border-top-right-radius: 0' : ''};
    ${borderRadiuslessRight ? 'border-bottom-right-radius: 0' : ''};
    ${borderRadiuslessLeft ? 'border-top-left-radius: 0' : ''};
    ${borderRadiuslessLeft ? 'border-bottom-left-radius: 0' : ''};
    border: solid 1px ${
      active
        ? theme.colors.primary['blue-2']
        : theme.colors.neutrals['white-darkest']
    };
    padding: ${size === 'small' ? '5px' : '12px 24px'};
    
    &:hover {
      border-color: ${theme.colors.primary['blue-2']};
      color: ${
        active ? theme.colors.neutrals.white : theme.colors.primary['blue-2']
      };
    }

    &:active {
      border-color: ${theme.colors.neutrals['gray-3']};
      color: ${
        active ? theme.colors.neutrals['white'] : theme.colors.neutrals['gray']
      };
    }
  `;
}

const Element = styled.button`
  ${props => sharedStyling(props.theme, props.fullWidth)};

  ${props =>
    props.appearance && props.appearance === 'link'
      ? linkStyling({
          theme: props.theme,
          color: props.color,
          alignement: props.alignement,
        })
      : null};

  ${props =>
    props.appearance && colors(props.theme).hasOwnProperty(props.appearance)
      ? defaultStyling(
          props.theme,
          props.appearance,
          props.borderRadiuslessRight,
          props.borderRadiuslessLeft
        )
      : null};

  ${props =>
    props.appearance && props.appearance === 'outline'
      ? outlineStyling(
          props.theme,
          props.active,
          props.borderRadiuslessRight,
          props.borderRadiuslessLeft,
          props.color,
          props.size
        )
      : null};
`;

const AnchorElement = styled.a`
  text-decoration: none;
  display: inline-block;
  text-align: center;
  ${props => sharedStyling(props.theme, props.fullWidth)};

  ${props =>
    props.appearance && props.appearance === 'link'
      ? linkStyling({
          theme: props.theme,
          color: props.color,
          alignement: props.alignement,
        })
      : null};

  ${props =>
    props.appearance && props.appearance.startsWith('default')
      ? defaultStyling(
          props.theme,
          props.appearance,
          props.borderRadiuslessRight,
          props.borderRadiuslessLeft
        )
      : null};

  ${props =>
    props.appearance && colors(props.theme).hasOwnProperty(props.appearance)
      ? defaultStyling(
          props.theme,
          props.appearance,
          props.borderRadiuslessRight,
          props.borderRadiuslessLeft
        )
      : null};

  ${props =>
    props.appearance && props.appearance === 'outline'
      ? outlineStyling(
          props.theme,
          props.active,
          props.borderRadiuslessRight,
          props.borderRadiuslessLeft,
          props.color,
          props.size
        )
      : null};
`;

const _Button = ({ loading = false, ...props }, ref) => {
  let E = props.href ? AnchorElement : Element;

  // button content
  let content = null;
  if (loading) {
    // TODO
    // all colors should get white
    // white / outline should get the grey spinner
    content = (
      <Spinner
        spinnerColor={props.spinnerColor ? props.spinnerColor : '#7d7d7d'}
      />
    );
  } else if (props.icon) {
    content = (
      <React.Fragment>
        <Span marginRight={props.children ? 5 : 0} verticalInline="middle">
          <Icon name={props.icon} fontSize={props.iconSize} />
        </Span>
        <Span verticalInline="middle">{props.children}</Span>
      </React.Fragment>
    );
  } else {
    content = props.children;
  }

  return (
    <E
      ref={ref}
      className={props.className}
      appearance={props.appearance ? props.appearance : 'default-blue'}
      size={props.size}
      onClick={props.onClick}
      href={props.href}
      type={props.type}
      disabled={props.disabled || loading}
      fullWidth={props.fullWidth}
      alignement={props.alignement}
      active={props.active}
      borderRadiuslessRight={props.borderRadiuslessRight}
      borderRadiuslessLeft={props.borderRadiuslessLeft}
      color={props.color}
      style={props.style}
      target={props.target}
      download={props.download}
      {...props}
    >
      {content}
    </E>
  );
};

export const StyledMaterialButton = styled(MaterialButton)`
  && {
    background-color: transparent;
    font-size: 11px;
    font-weight: 600;
    padding: 0px;
    min-width: auto;
    min-height: 100%;
    height: 100%;
    color: #242424;
    white-space: pre;
    display: block;
    transition: color 300ms ease-in;

    &:hover {
      background-color: transparent;
      color: ${props => props.theme.colors.interactive['color-7']};
      transition: color 300ms ease-in;
    }
  }
`;

const Button = React.forwardRef(_Button);

export default Button;
