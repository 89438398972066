import React, { useState } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Button from '@components/buttons';
import LinksModal from '@organisms/modals/LinksModal';
import Icon from '../../components/Icon';
import { Container as container } from '../../components/layout/Grid';
import { Placeholder } from './components';

export const NavSticky = styled.nav`
  position: fixed;
  top: 0;
  width: 100%;
  margin: 0;
  background: white;
  overflow: hidden;
  box-shadow: 0px 5px 15px ${props => props.theme.shadows.light};
  z-index: 9;
`;

const Element = styled.nav`
  border-bottom: 1px solid ${props => props.theme.shadows.light};
  background-color: ${props => props.theme.colors.neutrals['white-darker']};
  box-shadow: 0 1px 1px 0 ${props => props.theme.shadows.light};
`;

const Container = styled(container)`
  position: relative;
`;

const List = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 15px 0 0;
`;

export const ListItem = styled.li`
  flex-shrink: 0;
  color: ${props => props.theme.colors.neutrals['gray-dark']};
  padding: 0 16px;
  margin-bottom: 15px;

  &.active {
    color: ${props => props.theme.colors.primary['blue-2']};
  }

  /* add right border to overview item */
  :first-of-type {
    border-right: 1px solid ${props => props.theme.colors.neutrals['gray-dark']};
    padding-left: 0;
  }

  :last-of-type {
    padding-right: 0;
  }

  /* pull playlist + programs to right */
  :nth-last-of-type(2) {
    margin-right: 0;
    margin-left: auto;
  }

  @media (max-width: ${props => `${props.theme.viewports.tablet}px`}) {
    :first-of-type {
      border-right: none;
    }

    :nth-last-of-type(2) {
      margin-right: unset;
      margin-left: unset;
    }

    &.active {
      order: -1;
      padding-left: 0;
      border-right: 1px solid
        ${props => props.theme.colors.neutrals['gray-dark']};
      margin-right: 16px;
    }

    &.active:last-of-type {
      padding-right: 16px;
    }
  }
`;

const Anchor = styled(Link)`
  font-size: 13px;
  color: inherit;
  text-transform: capitalize;
  text-decoration: none;
  padding-bottom: 9px;
  cursor: pointer;

  ${ListItem}.active & {
    ${props => `
        font-weight: 600;
        border-bottom: 2px solid
          ${props.theme.colors.primary['blue-2']};
      `};
  }
`;

const ExpandButton = styled(Button)`
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  padding: 0 20px;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.6) 20%,
    rgba(255, 255, 255, 1) 40%
  );

  @media (min-width: ${props => props.theme.viewports.tablet}px) {
    display: none;
  }
`;

declare type LinksVideosType = {
  className?: string;
  showSticky: boolean;
  loading?: boolean;
  terms?: Array<{
    id: string;
    link: string;
    text: React.ReactNode;
    active: boolean;
  }>;
  height?: number;
};

const LinksVideos = (props: LinksVideosType) => {
  const [showMenuModal, setShowMenuModal] = useState(false);
  const ListWrapper = props.showSticky ? NavSticky : Element;

  return (
    <React.Fragment>
      {props.showSticky ? <Placeholder height={50} /> : null}
      <ListWrapper className={props.className}>
        <Container
          flexDirection={'column'}
          px={[15, 20, 25]}
          sx={{
            overflow: 'hidden',
          }}
        >
          <List>
            {props?.terms?.map(item => {
              return (
                <ListItem key={item.id} className={item.active ? 'active' : ''}>
                  <Anchor href={item.link}>{item.text}</Anchor>
                </ListItem>
              );
            })}
          </List>
          <ExpandButton
            appearance="outline"
            onClick={() => setShowMenuModal(true)}
          >
            <Icon name="ygb-icon-chevron-right" />
          </ExpandButton>
        </Container>
      </ListWrapper>
      <LinksModal
        open={showMenuModal}
        onClose={() => setShowMenuModal(false)}
        links={props?.terms?.map(item => ({
          href: item.link,
          text: item.text,
        }))}
      />
    </React.Fragment>
  );
};

export default LinksVideos;
